export function openBar(
  sdk,
  appToken,
  translation,
  titleTranslationKey,
  numberOfSteps,
) {
  return sdk.editor.openProgressBar(appToken, {
    title: translation.t(titleTranslationKey),
    totalSteps: numberOfSteps,
  });
}

export function setBarStep(
  sdk,
  appToken,
  translation,
  stepTranslation,
  stepNumber,
) {
  return sdk.editor.updateProgressBar(appToken, {
    stepTitle: translation.t(stepTranslation),
    currentStep: stepNumber,
  });
}

export function closeBar(sdk, appToken, isError = false) {
  return sdk.editor.closeProgressBar(appToken, {
    isError,
  });
}
